import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import ElementLocale from 'element-ui/lib/locale'
import enLocale from './en'
import zhLocale from './zh-CN'

Vue.use(VueI18n)

const messages = {
  'en_US': {
    ...enLocale,
    ...elementEnLocale
  },
  'zh_CN': {
    ...zhLocale,
    ...elementZhLocale
  }
}

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'zh_CN',
  fallbackLocale: 'zh_CN',
  messages,
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n
