const user = {
    state: {
        userId: '',
        account: '',
        isAdmin: false,
        userInfo: null
    },

    mutations: {
        setUserInfo(state, payload) {
            state.userId = payload.userId
            state.account = payload.account
            state.isAdmin = payload.isAdmin
            state.userInfo = payload
            localStorage.setItem('userInfo', JSON.stringify(payload))
        },

        removeUserInfo(state) {
            state.userId = ''
            state.account = ''
            state.isAdmin = false
            state.userInfo = null
            localStorage.removeItem('userInfo')
        }
    },

    actions: {},
}

export default user
