const menu = {
    state: {
        menuList: [], //主菜单列表
        newPageList: [], //打开新页面的链接
        permissions: [], //权限列表
        isMenuReady: false, //菜单是否已加载
        sidebarCollapse: false //侧边栏菜单折叠
    },

    mutations: {
        //设置菜单是否已加载
        setIsMenuReady(state, payload) {
            state.isMenuReady = payload
        },

        //设置菜单列表
        setMenuList(state, payload) {
            localStorage.setItem('menuList', JSON.stringify(payload))
            state.menuList = payload
        },

        //设置打开新页面的链接
        setNewPageList(state, payload) {
            localStorage.setItem('newPageList', JSON.stringify(payload))
            state.newPageList = payload
        },

        //设置权限列表
        setPermissions(state, payload) {
            localStorage.setItem('permissions', JSON.stringify(payload))
            state.permissions = payload
        },

        //设置侧边栏菜单折叠
        setSidebarCollapse(state, payload) {
            state.sidebarCollapse = payload
        },

        //退出清空数据
        removeMenus(state) {
            state.menuList = []
            state.newPageList = []
            state.permissions = []
            localStorage.removeItem('menuList')
            localStorage.removeItem('newPageList')
            localStorage.removeItem('permissions')
        }
    },

    actions: {}
}

export default menu
