import {USER_SYS_NOTICE, findById,changeEnableState} from '@/api/system'

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            show: false,
            loading: false,
            detailObj: {}
        }
    },
    watch: {
        value(bool) {
            this.show = bool
            if (bool) {
                this.id && this.getDetail()
            }
        }
    },
    methods: {
        getDetail() {
            this.loading = true
            findById(USER_SYS_NOTICE, this.id).then(res => {
                this.loading = false
                if (res.code === 0) {
                    this.detailObj = res.data
                }
            }).catch(_err => {
                this.loading = false
            })
        },

        closeDialog() {
            this.$emit('input', false)
            this.detailObj = {}
        },

        /**
         * 已读
         */
        read() {
            if(this.detailObj.enabled === '1'){
                changeEnableState(this.detailObj.entityId, '0', USER_SYS_NOTICE).then(res => {
                    if (res.code === 0) {
                        this.$emit('on-success', USER_SYS_NOTICE)
                        this.closeDialog()
                    }
                }).catch(_err => {
                    this.$message.error(_err)
                })
            }else{
                this.closeDialog()
            }
        },
    }
}
