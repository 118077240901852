/**
 * 指令操作密码
 * @type {string}
 */
export const CMD_PWD = '123123'

/**
 * 客户端登录类型
 */
export const LoginType = {
    BROWSER: '0'
}

/**
 * 终端类别
 */
export const TerminalCategory = {
    CABINET: '0',
    BATTERY: '1',
    VEHICLE: '2'
}

/**
 * 地图marker类型
 */
export const MarkerType = {
    STATION: 'S',
    CABINET: 'C',
    BATTERY: 'B'
}

/**
 * 是：1，否：0
 */
export const YesNo = {
    NO: '0',
    YES: '1'
}

/**
 * 工单状态
 */
export const WorkOrderStatus = {
    PENDING_ASSIGN: '0',
    ASSIGNED: '1',
    IN_PROGRESS: '2',
    COMPLETED: '3',
    CANCELLED: '4'
}

/**
 * 钱包类型(余额、换电押金、车辆押金)
 */
export const WalletType = {
    BALANCE: '0',
    SWAP_DEPOSIT: '1',
    VEHICLE_DEPOSIT: '2'
}

/**
 * 钱包交易类型(充值、提现、扣款)
 * @type {{CHARGEBACK: string, RECHARGE: string}}
 */
export const WalletTransactionType = {
    RECHARGE: '0',
    WITHDRAWAL: '3',
    CHARGEBACK: '5'
}

/**
 * 合作商交易类型(充值、退款)
 */
export const PartnerTransaction = {
    RECHARGE: '0',
    REFUND: '1'
}

export const FundType = {
    PARTNER_RECHARGE: '18',
    PARTNER_REFUND: '19'
}

/**
 * 套餐状态(未上架、已上架、已下架)
 */
export const SetmealStatus = {
    NOT_LISTED: '0',
    LISTED: '1',
    OFF_SHELF: '2'
}

/**
 * 订单状态
 */
export const OrderStatus = {
    IN_PROGRESS: '1',
    FINISHED: '2',
    PENDING: '3',
    CANCELED: '4',
    FROZEN: '5'
}

/**
 * 客户套餐状态
 */
export const CustomerSetmealStatus = {
    IN_PROGRESS: '0',
    FROZEN: '1',
    COMPLETED: '2',
    OVERDUE: '3'
}

/**
 * 计费类型(时间、次数、租电)
 */
export const BillingType = {
    TIME: '0',
    COUNT: '1',
    RENTAL_BATTERY: '2'
}

/**
 * 换电状态(未完成、等待中、失败、成功)
 * @type {{}}
 */
export const SwapStatus = {
    UNFINISHED: '0',
    WAITING: '1',
    SWAP_FAIL: '2',
    SWAP_SUCCESS: '3'
}

export const ChargeStatus = {
    FAIL: '0',
    WAITING_PUT: '1',
    CHARGING: '2',
    WAITING_TAKE: '3',
    COMPLETED: '4'
}

/**
 * 租车业务类型
 * @type {{RENTED: string, RETURNED: string}}
 */
export const VehicleServiceType = {
    RENTED: '0',
    RETURNED: '1'
}

/**
 * 审核状态(审核中、已通过、未通过、已撤销)
 * @type {{}}
 */
export const AuditStatus = {
    AUDITING: '0',
    APPROVED: '1',
    REJECTED: '2',
    REVOKED: '3'
}

/**
 * 公告状态(待生效、已发布、已下线、已过期)
 * @type {{}}
 */
export const AnnouncementStatus = {
    PENDING: '0',
    PUBLISHED: '1',
    OFFLINE: '2',
    EXPIRED: '3'
}

/**
 * 认证状态(认证失败、已身份认证、未认证、已人脸认证)
 * @type {{SUCCESS: string, FAILED: string, NONE: string}}
 */
export const AuthStatus = {
    FAILED: '0',
    SUCCESS: '1',
    NONE: '2',
    FACE_SUCCESS: '3'
}

/**
 * 用户审核状态(待审核、已通过、未通过)
 */
export const UserAuditStatus = {
    PENDING: '0',
    APPROVED: '1',
    REJECTED: '2'
}

/**
 * 在线状态(离线、在线、从未上线)
 * @type {{NO: string, YES: string, NONE: string}}
 */
export const OnlineStatus = {
    NO: '0',
    YES: '1',
    NONE: '2'
}

/**
 * 定位状态(未定位、已定位)
 * @type {{NO: string, YES: string}}
 */
export const PositionStatus = {
    NO: '0',
    YES: '1'
}

/**
 * 电池业务类型(站点库存、在柜、租赁中、维修中、已报废、已销售)
 * @type {{REPAIRING: string, SCRAP: string, SOLD: string, ON_LEASE: string, IN_CABINET: string, SITE_STOCK: string}}
 */
export const BatteryBusiness = {
    SITE_STOCK: '0',
    IN_CABINET: '1',
    ON_LEASE: '2',
    REPAIRING: '3',
    SCRAP: '4',
    SOLD: '5'
}

/**
 * 车辆业务类型(站点库存、租赁中、维修中、已报废、已销售)
 * @type {{REPAIRING: string, SCRAP: string, SOLD: string, ON_LEASE: string, IN_CABINET: string, SITE_STOCK: string}}
 */
export const VehicleBusiness = {
    SITE_STOCK: '0',
    ON_LEASE: '1',
    REPAIRING: '2',
    SCRAP: '3',
    SOLD: '4'
}

/**
 * 电柜类型(换电柜、直流充电柜、交流充电柜、充换一体柜)
 * @type {{CHARGER: string, CABINET: string}}
 */
export const CabinetType = {
    CABINET: '0',
    DC_CHARGER: '1',
    AC_CHARGER: '2',
    CHARGE_SWAP_MIXED: '3'
}

/**
 * App消息类型(低电量通知、充电完成通知)
 * @type {{CHARGE_COMPLETE: string, LOW_POWER: string}}
 */
export const AppMessageType = {
    LOW_POWER: '0',
    CHARGE_COMPLETE: '1'
}

/**
 * 用户类型(系统用户、租赁用户)
 * @type {{RENTAL_USER: string, SYSTEM_USER: string}}
 */
export const UserType = {
    SYSTEM_USER: '0',
    RENTAL_USER: '1'
}

/**
 * 邀请人类型(推广业务员、租赁用户)
 * @type {{PROMOTER: string, SYSTEM_USER: string}}
 */
export const InviterType = {
    PROMOTER: '0',
    RENTAL_USER: '1'
}

/**
 * 卡券使用状态(未使用、已使用、已撤销、已过期)
 * @type {{REVOKED: string, EXPIRED: string, UNUSED: string, USED: string}}
 */
export const CouponUsageStatus = {
    UNUSED: '0',
    USED: '1',
    REVOKED: '2',
    EXPIRED: '3'
}

/**
 * 套餐冻结状态(失败、冻结中、审核中、已结束)
 * @type {{FREEZING: string, COMPLETED: string, FAILED: string, UNDER_REVIEW: string}}
 */
export const FreezeStatus = {
    FAILED: '0',
    FREEZING: '1',
    UNDER_REVIEW: '2',
    COMPLETED: '3'
}

/**
 * APP类型(微信、支付宝)
 * @type {{ALIPAY: string, WECHAT: string}}
 */
export const AppType = {
    WECHAT: '0',
    ALIPAY: '1'
}

/**
 * 站点类型(租赁站点、租电门店、租车门店、综合门店)
 * @type {{VEHICLE_STORE: string, RENTAL_SITE: string, GENERAL_STORE: string, MERCHANT_STORE: string}}
 */
export const StationType = {
    RENTAL_SITE: '0',
    MERCHANT_STORE: '1',
    VEHICLE_STORE: '2',
    GENERAL_STORE: '3'
}

/**
 * 入组状态(已入组、已退组)
 * @type {{LEFT: string, JOINED: string}}
 */
export const GroupStatus = {
    JOINED: '0',
    LEFT: '1'
}

/**
 * 支付方式(先用后付、合作商代付)
 */
export const PaymentMethod = {
    BACKEND: '9',
    PAY_LATER: '10',
    PARTNER_SETTLEMENT: '11'
}

/**
 * 结算支付方式(微信支付、支付宝、余额支付、线下支付、合作商余额)
 * @type {{WECHAT_PAY: string, ALIPAY: string, PARTNER_BALANCE: string, BACKEND: string, BALANCE: string}}
 */
export const SettledPaymentMethod = {
    WECHAT_PAY: '0',
    ALIPAY: '1',
    BALANCE: '3',
    BACKEND: '9',
    PARTNER_BALANCE: '12'
}

/**
 * 分账主体(合作商、个人)
 */
export const SharingEntity = {
    PARTNER: '0',
    PERSONAL: '1'
}

/**
 * 分账支付方式(微信支付、支付宝)
 */
export const SharingPaymentMethod = {
    WECHAT_PAY: '0',
    ALIPAY: '1',
    BALANCE: '2'
}

/**
 * 分账方式(按比例、固定金额)
 */
export const AccountMethod = {
    BY_RATIO: '0',
    FIXED_AMOUNT: '1'
}

/**
 * 分账状态(待分账、分账中、已分账、分账失败)
 */
export const SharingStatus = {
    WAITING: '0',
    PROCESSING: '1',
    SUCCESS: '2',
    FAILURE: '3'
}

/**
 * 分账资金解冻状态(未解冻、解冻中、已解冻、解冻失败)
 * @type {{IN_PROGRESS: string, THAWED: string, UNFROZEN: string}}
 */
export const SharingFreezeStatus = {
    UNFROZEN: '0',
    IN_PROGRESS: '1',
    THAWED: '2',
    FAILURE: '3'
}

/**
 * 优惠券状态(待生效、生效中、已作废、已过期)
 * @type {{ACTIVE: string, EXPIRED: string, PENDING: string, INVALID: string}}
 */
export const CouponStatus = {
    PENDING: '0',
    ACTIVE: '1',
    INVALID: '2',
    EXPIRED: '3'
}

/**
 * 调拨状态(待接收、已接收、已取消)
 * @type {{RECEIVED: string, CANCELLED: string, PENDING_RECEIPT: string}}
 */
export const AllocationStatus = {
    PENDING_RECEIPT: '0',
    RECEIVED: '1',
    CANCELLED: '2'
}

/**
 * 租赁类型(电池、充电、车辆)
 * @type {{BATTERY: string, CHARGING: string, VEHICLE: string}}
 */
export const RentalType = {
    BATTERY: '0',
    CHARGING: '1',
    VEHICLE: '2'
}
