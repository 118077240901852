import Login from "@/views/login"
import Error from "@/views/error"
import Layout from '@/views/layout/index'
import {loadComponent} from '@/utils/menu'

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/error',
        name: 'error',
        component: Error
    },
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path*',
                component: loadComponent('redirect')
            }
        ]
    }
]

export default routes
