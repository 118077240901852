import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './assets/i18n'
import 'normalize.css/normalize.css'
import dayjs from 'dayjs'
import SelectTree from './components/select-tree'
import SvgIcon from './components/svg-icon'
import Permission from './directive/permission'
import {Button, Select, Container, Header, Main, Aside, Menu, Submenu, MenuItem, Dropdown, DropdownMenu, DropdownItem, Carousel, CarouselItem, Form, FormItem, Input, Checkbox, CheckboxGroup, Empty, Loading, MessageBox, Message, Notification, Option, Tree, Table, TableColumn, Pagination, Row, Col, Dialog, Descriptions, DescriptionsItem, Switch, RadioGroup, Radio, Popover, InputNumber, RadioButton, Breadcrumb, BreadcrumbItem, Tooltip, DatePicker, TimePicker, Collapse, CollapseItem, Tag, Upload, Image, Autocomplete, Slider, Link, Tabs, TabPane, Progress, Card} from 'element-ui'
import './assets/styles/element-variables.scss'
import './assets/styles/common.scss'
import './assets/icons/iconfont.css'
import './assets/icons'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import Scrollbar from "element-ui/packages/scrollbar"
import VueAMap from '@vuemap/vue-amap'
import '@vuemap/vue-amap/dist/style.css'

Vue.config.productionTip = false
Vue.component(SelectTree.name, SelectTree)
Vue.component(SvgIcon.name, SvgIcon)
Vue.prototype.$ELEMENT = {size: 'small', zIndex: 3000}
const elementComponents = [Button, Select, Container, Header, Main, Aside, Menu, Submenu, MenuItem, Dropdown, DropdownMenu, DropdownItem, Carousel, CarouselItem, Form, FormItem, Input, Checkbox, CheckboxGroup, Empty, Option, Tree, Table, TableColumn, Pagination, Row, Col, Dialog, Descriptions, DescriptionsItem, Switch, RadioGroup, Radio, RadioButton, Popover, InputNumber, Breadcrumb, BreadcrumbItem, Tooltip, DatePicker, TimePicker, Collapse, CollapseItem, Tag, Scrollbar, Upload, Image, Autocomplete, Slider, Link, Tabs, TabPane, Progress, Card]
elementComponents.forEach(component => Vue.use(component))
Vue.use(Loading.directive)
Vue.use(Permission)
Vue.use(VXETable)
Vue.use(VueAMap)
//插件使用参考：https://lbs.amap.com/api/javascript-api/guide/abc/plugins#plugins
VueAMap.initAMapApiLoader({
    key: window.globalConfig.amapKey,
    securityJsCode: window.globalConfig.amapSecurityJsCode,
    plugins: ["AMap.MarkerClusterer"],
    version: '1.4.15',
    Loca: null
})

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message
Vue.prototype.dayjs = dayjs

const debounce = (fn, delay) => {
    let timer = null
    return function () {
        let context = this
        let args = arguments
        clearTimeout(timer)
        timer = setTimeout(function () {
            fn.apply(context, args)
        }, delay)
    }
}

const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16)
        super(callback)
    }
}

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
