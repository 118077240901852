import axios from 'axios'
import store from '@/store'
import {Message} from 'element-ui'
import {logoutHandle} from '@/utils/auth'

const service = axios.create({
    timeout: 120000,
    baseURL: window.globalConfig.domain
})

/**
 * 请求拦截器
 */
service.interceptors.request.use(
    config => {
        let userInfo = store.getters.userInfo
        if (userInfo && userInfo.tokenName && userInfo.token) {
            config.headers[userInfo.tokenName] = userInfo.token
        }
        config.headers['Content-Type'] = 'application/json'
        return config
    },
    error => {
        Promise.reject(error)
    }
)

var hasError = false
let response = res => {
    const data = res.data
    let status = res.status
    if (status === null || status === undefined) {
        return null
    }
    if (status === 200) {
        //处理文件下载
        if (res.headers && res.headers['content-disposition'] && res.headers['content-type']) {
            return handleDownloadFileResp(res)
        }

        //处理登录状态过期
        if (data.code === -1) {
            if (hasError) {
                return Promise.reject(data)
            }
            hasError = true
            Message({
                type: 'warning',
                message: '登录状态已过期，请重新登录',
                showClose: true
            })
            setTimeout(() => {
                hasError = false
            }, 3000)
            logoutHandle(true)
            return Promise.reject(data)
        }

        if (data instanceof Blob && data.type.toLowerCase().indexOf('json') !== -1) {
            return data
        }

        //处理异常消息
        if (data.code !== 0) {
            Message({
                type: 'error',
                message: data.msg,
                showClose: true
            })
            return Promise.reject(data.msg)
        }
        return data
    } else if (status === 404) {
        //文件下载需要使用headers
        if (res.headers && res.headers['content-disposition']) {
            return {'code': 1, 'msg': '文件不存在'}
        }
    }
    return data
}

/**
 * 处理文件下载响应
 * @param res
 * @returns {{msg: string, code: number}|*}
 */
let handleDownloadFileResp = res => {
    if (res.headers['content-type'].indexOf('application/octet-stream') >= 0 || res.headers['content-type'].indexOf('application/vnd') >= 0) {
        let disposition = res.headers['content-disposition']
        //获取响应头的文件名
        let filename = decodeURI(disposition.substring(disposition.indexOf('filename=') + 9, disposition.length))
        const content = res.data
        // for Non-IE (chrome, firefox etc.)
        let a = document.createElement('a')
        let blobData = new Blob([content])
        a.href = URL.createObjectURL(blobData)
        a.download = filename
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(a.href)
        document.body.removeChild(a)
        return {code: 0, msg: 'success'}
    }
    if (res.data instanceof Blob && res.data.type.toLowerCase().indexOf('json') !== -1) {
        const reader = new FileReader()
        reader.readAsText(res.data, 'utf-8')
        reader.onload = function (e) {
            let result = reader.result
            let errorMsg = result ? JSON.parse(reader.result).msg : ''
            if (errorMsg) {
                Message({
                    type: 'error',
                    message: errorMsg,
                    showClose: true
                })
            }
        }
    }
    return res.data
}

let responseError = err => {
    let errorMsg = '请求异常'
    if (err && err.message) {
        errorMsg = errorMsg + ':' + err.message
    }
    Message({
        type: 'error',
        message: errorMsg,
        showClose: true
    })
    return Promise.reject(err)
}

/**
 * 响应拦截器
 */
service.interceptors.response.use(response, responseError)

export default service
