<template>
  <div>
    <el-dialog width="600px" :close-on-click-modal="false" :visible.sync="show" @close="closeDialog">
      <div slot="title">
        <span class="dialog-title">{{ $t('login.changePwd') }}</span>
      </div>
      <el-form :model="params" ref="editForm" :rules="rules" label-width="120px">
        <el-form-item prop="oldPwd" :label="$t('changePwd.oldPwd')">
          <el-input type="password" clearable v-model.trim="params.oldPwd"/>
        </el-form-item>
        <el-form-item prop="newPwd" :label="$t('changePwd.newPwd')">
          <el-input type="password" clearable v-model.trim="params.newPwd"/>
          <ul class="form-item-tip">
            <li class="tip-title">{{ $t('common.tips') }}</li>
            <li class="tip-content">{{ $t('user.pwdTips') }}</li>
          </ul>
        </el-form-item>
        <el-form-item prop="confirmPwd" :label="$t('changePwd.confirmPwd')">
          <el-input type="password" clearable v-model.trim="params.confirmPwd"/>
        </el-form-item>

      </el-form>
      <span slot="footer">
        <el-button plain @click="resetForm">{{ $t('operation.reset') }}</el-button>
        <el-button type="primary" :loading="loading" @click="save">{{ $t('operation.save') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {isPassword} from "@/utils/validate"
import {changePassword, logout} from '@/api/security'
import {logoutHandle} from "@/utils/auth"

export default {
  name: "ChangePassword",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(bool) {
      this.show = bool
    }
  },
  data() {
    const validateNewPwd = (rule, value, callback) => {
      if (value === null || value === '') {
        callback(new Error(this.$t('changePwd.newPwdValidTip')))
      } else if (!isPassword(value)) {
        callback(new Error(this.$t('user.incorrectPwdTip')))
      } else {
        callback()
      }
    }

    const validateConfirmPwd = (rule, value, callback) => {
      if (value === null || value === '') {
        callback(new Error(this.$t('changePwd.confirmPwdValidTip')))
      } else if (this.params.newPwd && value !== this.params.newPwd) {
        callback(new Error(this.$t('changePwd.pwdValidTip')))
      } else {
        callback()
      }
    }

    return {
      show: false,
      loading: false,
      params: {
        oldPwd: '',
        newPwd: '',
        confirmPwd: ''
      },
      rules: {
        oldPwd: [{required: true, message: this.$t('changePwd.oldPwdValidTip'), trigger: 'blur'}],
        newPwd: [{required: true, validator: validateNewPwd, trigger: 'blur'}],
        confirmPwd: [{validator: validateConfirmPwd, trigger: 'blur'}]
      }
    }
  },
  methods: {
    save() {
      this.$refs.editForm.validate((valid) => {
        if (!valid) {
          return
        }
        let formData = {}
        formData.oldPwd = this.params.oldPwd
        formData.newPwd = this.params.newPwd
        changePassword(formData).then(res => {
          this.loading = false
          if (res.code === 0 && res.data) {
            this.show = false
            //退出系统让用户重新登录
            this.$alert(this.$t('changePwd.loginAgainTip'), this.$t('common.tips'), {
              confirmButtonText: this.$t('common.confirmTip'),
              showClose: false,
              callback: _action => {
                logout().then(_res => {
                  logoutHandle()
                }).catch(_err => {
                  logoutHandle()
                })
              }
            })
          }
        }).catch(_err => {
          this.loading = false
        })
      })
    },

    resetForm() {
      this.$refs.editForm.resetFields()
    },

    closeDialog() {
      this.$emit('input', false)
      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
</style>
