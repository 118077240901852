import { render, staticRenderFns } from "./index.vue?vue&type=template&id=36b17f70&scoped=true"
import script from "./js/index.js?vue&type=script&lang=js&external"
export * from "./js/index.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b17f70",
  null
  
)

export default component.exports