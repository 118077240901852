<template>
  <div class="container">
    <div class="header">
      <div class="logo-title">智慧出行大数据平台</div>
      <!--      <img class="logo" :src="logo">-->
      <div class="lang-box">
        <span :class="['lang-border', lang === 'zh_CN' ? 'lang-active' : '']" @click="changeLocale('zh_CN')">
          {{ $t('login.cnLang') }}
        </span>
        <span :class="[showContact ? 'lang-border' : '', lang === 'en_US' ? 'lang-active' : '']" @click="changeLocale('en_US')">
          {{ $t('login.enLang') }}
        </span>
        <span style="padding: 0 0 0 15px" @click="openCompanyUrl" v-if="showContact">{{ $t('login.contactUs') }}</span>
      </div>
    </div>
    <div class="content">
      <!--      <el-carousel class="carousel">-->
      <!--        <el-carousel-item v-for="(item, index) in images" :key="index">-->
      <!--          <img :src="item.url" style="height: 100%; width: 100%;"/>-->
      <!--        </el-carousel-item>-->
      <!--      </el-carousel>-->
      <div class="login-box">
        <div class="login-tabs">
          <div class="login-item" @click="changeLoginType('accountLogin')">
            <div :class="['title', activeLoginType === 'accountLogin' ? 'active-tab' : '']">{{
                $t('login.accountLogin')
              }}
            </div>
          </div>
          <div class="login-item" @click="changeLoginType('smsLogin')">
            <div :class="['title', activeLoginType === 'smsLogin' ? 'active-tab' : '']">{{ $t('login.smsLogin') }}</div>
          </div>
        </div>
        <div class="form-box" v-show="activeLoginType === 'accountLogin'">
          <el-form ref="accountLoginForm" :model="params" :rules="accountLoginRules" style="height: 100%">
            <el-form-item prop="account">
              <el-input v-model.trim="params.account" :placeholder="$t('login.accountPlaceholder')">
                <i slot="prepend" class="el-icon-user"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input :type="inputType" v-model.trim="params.password" :placeholder="$t('login.pwdPlaceholder')"
                        autocomplete="new-password">
                <i slot="prepend" class="el-icon-lock"></i>
                <div slot="suffix" class="input-append" @click="changeInputType" v-if="showPassword">
                  <i :class="['sf-icon', inputType === 'password' ? 'sf-icon-biyan' : 'sf-icon-zhengyan']"></i>
                </div>
              </el-input>
            </el-form-item>
            <div class="remember-me">
              <el-checkbox v-model="rememberPwd">{{ $t('login.rememberPwd') }}</el-checkbox>
            </div>
            <el-form-item>
              <slider-check ref="sliderCheck" v-model="isLock" :content="$t('login.sliderCheckTip')"
                            :successContent="$t('login.sliderCheckSuccess')" @change="handlerSliderCheck"></slider-check>
            </el-form-item>
          </el-form>
        </div>
        <div class="form-box" v-show="activeLoginType === 'smsLogin'">
          <el-form ref="smsLoginForm" :model="params" :rules="smsLoginRules">
            <el-form-item prop="phone">
              <el-input v-model.trim="params.phone" :placeholder="$t('login.phonePlaceholder')">
                <i slot="prepend" class="el-icon-mobile-phone"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="captcha" style="margin-top: 35px;">
              <el-input v-model.trim="params.captcha" :placeholder="$t('login.captchaPlaceholder')">
                <i slot="prepend" class="el-icon-circle-check"></i>
                <div slot="suffix">
                  <el-button type="primary" size="mini" :loading="captchaBtnLoading" :disabled="captchaBtnDisabled"
                             @click="getSmsCaptcha">{{ captchaBtnTxt }}
                  </el-button>
                </div>
              </el-input>
            </el-form-item>
          </el-form>
          <div class="login-btn">
            <el-button type="primary" size="medium" style="width: 100%;" @click="handleSmsLogin">{{
                $t('login.login')
              }}
            </el-button>
          </div>
        </div>
        <div class="form-footer"></div>
      </div>
    </div>
    <div class="footer">
      <span>Copyright 2020 - {{ new Date().getFullYear() }} {{ companyName }}. All Rights Reserved</span>
      <img src="~@/assets/images/login/beian.png" style="margin: 0 5px;">
    </div>
    <sms-login-dialog v-model="smsLoginObj.show" :phone="smsLoginObj.phone"
                      :route-path="smsLoginObj.routePath"></sms-login-dialog>
  </div>
</template>

<script>
import Logo from '@/assets/images/login/logo.png'
import SliderCheck from '@/components/slider-check'
import {requestSmsCaptcha, weakLogin, smsLogin} from '@/api/security'
import {encrypt} from '@/utils/rsa'
import {encryptAes, decryptAes} from '@/utils/aes'
import {LoginType} from '@/utils/constant'
import qs from 'qs'
import SmsLoginDialog from './sms_login'

export default {
  name: "Login",
  components: {SliderCheck, SmsLoginDialog},
  watch: {
    $route: {
      handler: function (route) {
        const data = route.query
        if (data && data.redirect) {
          this.redirect = data.redirect
          delete data.redirect
          if (JSON.stringify(data) !== '{}') {
            this.redirect = this.redirect + '&' + qs.stringify(data, {indices: false})
          }
        }
      },
      immediate: true
    }
  },
  created() {
    this.lang = localStorage.getItem('locale') || 'zh_CN'
    this.params.account = localStorage.getItem('account') || ''
    let localStoragePwd = localStorage.getItem('password') || ''
    if (this.params.account) {
      this.rememberPwd = true
    }
    //localStorage保存的密码使用AES解密还原
    if (localStoragePwd) {
      this.params.password = decryptAes(localStoragePwd)
    }

    //根据域名动态显示公司名称
    const hostname = window.location.hostname
    this.companyName = this.companyMappings[hostname]
    if (!this.companyName) {
      this.companyName = '深圳市盾创科技有限公司'
      this.showContact = true
    }
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('login.phoneValidTip')))
      } else if (!/^(1[3456789])\d{9}$/.test(value)) {
        callback(new Error(this.$t('login.correctPhoneTip')))
      } else {
        callback()
      }
    }

    return {
      lang: 'zh_CN',
      logo: Logo,
      images: [
        {url: require("@/assets/images/login/carousel_1.png")},
        {url: require("@/assets/images/login/carousel_2.png")},
        {url: require("@/assets/images/login/carousel_3.png")}
      ],
      companyMappings: {
        'fhl.gdemai.cn': '深圳风火轮科创科技有限公司'
      },
      companyName: '',
      inputType: 'password',
      showContact: false,
      showPassword: false,
      accountLoginRules: {
        account: {required: true, message: this.$t('login.accountValidTip'), trigger: 'blur'},
        password: {required: true, message: this.$t('login.pwdValidTip'), trigger: 'blur'}
      },
      smsLoginRules: {
        phone: {required: true, validator: checkPhone, trigger: 'blur'},
        captcha: {required: true, message: this.$t('login.captchaValidTip'), trigger: 'blur'}
      },
      isLock: false,
      localStoragePwd: '',
      rememberPwd: false,
      params: {
        account: '',
        password: '',
        phone: '',
        captcha: ''
      },
      redirect: null,
      activeLoginType: 'accountLogin',
      captchaBtnLoading: false,
      captchaBtnDisabled: false,
      captchaBtnTxt: this.$t('login.getCaptcha'),
      smsCaptchaTime: 0,
      smsLoginObj: {
        show: false,
        phone: '',
        routePath: ''
      }
    }
  },
  methods: {
    changeInputType() {
      this.inputType = this.inputType === 'password' ? 'text' : 'password'
    },

    handlerSliderCheck(data) {
      if (!data) {
        return
      }
      this.$refs.accountLoginForm.validate((valid) => {
        if (!valid) {
          this.resetSliderCheck()
          return
        }
        this.handleAccountLogin()
      })
    },

    changeLocale(locale) {
      localStorage.setItem('locale', locale)
      this.$i18n.locale = locale
      this.lang = locale
    },

    resetSliderCheck() {
      this.isLock = false
      if (this.$refs.sliderCheck) {
        this.$refs.sliderCheck.init()
      }
    },

    changeLoginType(loginType) {
      this.activeLoginType = loginType
    },

    getSmsCaptcha() {
      this.$refs.smsLoginForm.clearValidate()
      this.$refs.smsLoginForm.validateField('phone', (err) => {
        if (err) {
          return
        }

        let params = {
          phone: this.params.phone
        }
        this.captchaBtnLoading = true
        requestSmsCaptcha(params).then(res => {
          this.captchaBtnLoading = false
          if (res.data === true) {
            this.smsCaptchaTime = 60
            this.captchaBtnDisabled = true
            this.changeSmsCaptchaBtn()
          }
        }).catch(_err => {
          this.captchaBtnLoading = false
        })
      })
    },

    //验证码倒计时
    changeSmsCaptchaBtn() {
      if (this.smsCaptchaTime > 0) {
        this.smsCaptchaTime--
        this.captchaBtnTxt = this.smsCaptchaTime + "秒后获取"
        setTimeout(this.changeSmsCaptchaBtn, 1000)
      } else {
        this.smsCaptchaTime = 0
        this.captchaBtnTxt = this.$t('login.getCaptcha')
        this.captchaBtnDisabled = false
      }
    },

    /**
     * 处理账号登录
     */
    handleAccountLogin() {
      //提交到服务器的密码使用RSA加密
      const user = {
        account: this.params.account,
        password: encrypt(this.params.password),
        pwdEncrypt: true,
        language: this.lang
      }

      if (this.rememberPwd) {
        localStorage.setItem('account', user.account)
        //localStorage保存的密码使用AES加密
        localStorage.setItem('password', encryptAes(this.params.password))
      } else {
        localStorage.removeItem('account')
        localStorage.removeItem('password')
      }

      //提交登录请求
      weakLogin(user).then(res => {
        if (res.code === 0) {
          let resultData = res.data
          if (resultData.safetyMode) {
            let path = this.redirect || '/'
            this.smsLoginObj = {show: true, phone: resultData.phoneNum, routePath: path}
            this.resetSliderCheck()
          } else {
            this.$message.success(this.$t('login.loginSuccess'))
            this.$store.commit('setUserInfo', res.data)
            let path = this.redirect || '/'
            this.$router.replace(path).catch(_err => {
              this.resetSliderCheck()
            })
          }
        } else {
          this.resetSliderCheck()
        }
      }).catch(_err => {
        this.resetSliderCheck()
      })
    },

    handleSmsLogin() {
      this.$refs.smsLoginForm.validate((valid) => {
        if (!valid) {
          return
        }

        let params = {
          phone: this.params.phone,
          captcha: this.params.captcha,
          loginType: LoginType.BROWSER
        }
        smsLogin(params).then(res => {
          if (res.code === 0) {
            this.$message.success(this.$t('login.loginSuccess'))
            this.$store.commit('setUserInfo', res.data)
            let path = this.redirect || '/'
            this.$router.replace(path).catch(_err => {
            })
          }
        }).catch(_err => {
        })
      })
    },

    openCompanyUrl() {
      window.open("https://www.domchar.com/", '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/styles/login";
</style>
