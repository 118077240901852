import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair
const publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKXZ4l8Yl8PwPLJk5kFrziQl0Z8La2ZWPSzPFgMUeEbeW4gQ2Ac4bvudzvVaGFyvfmFL2y7/BzBLsODRFiX9FeECAwEAAQ=='

const privateKey = 'MIIBUwIBADANBgkqhkiG9w0BAQEFAASCAT0wggE5AgEAAkEApdniXxiXw/A8smTmQWvOJCXRnwtrZlY9LM8WAxR4Rt5biBDYBzhu+53O9VoYXK9+YUvbLv8HMEuw4NEWJf0V4QIDAQABAkBb40nuUhvTrUiTth90Y5xEnsdzDMqmWmVubBXQAwlYiXj+u/qJa60JU9EcAfzQr87fzKKkFeVElpwjLtTCo0UhAiEA2yK2K1PV54a48vbxj0k18VFpBQhtj7Ofx5VmwLLy680CIQDBwGyk6D9NVxQxUabXURANTCgGqpq1E2yNIht36lxGZQIgIsOt/M+vBq0D2F++0Ml/ikotDARbRUns1j/obje1fB0CIF3bHAYKZ1fMvgBiIGMfZpo6cQ1Bqa3b2fLIOewuAEMtAiBc+Dzn/l9D1gBG+WJx75mbD9PZDvtVpLZcChRzrTJ6HQ=='

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对需要加密的数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey)
  return encryptor.decrypt(txt)
}

